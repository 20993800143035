<template>
  <div>
    <h1>Custom Spend</h1>
    <form
      class="custom-spend__form fc-container-sm fc-flex fc-column fc-align-center">
      <v-date-picker
        v-model="startDate"
        class="fc-margin-y-6"
        color="red" />
      <FCInput
        v-model="days"
        type="number"
        title="Number of Days"
        name="number-of-days" />
      <FCInput
        v-model="spend"
        type="number"
        title="Spend Per Days"
        name="spend-per-days" />
      <FCSelect
        v-model="spendTypeSelected"
        :options="getSpendTypeOptions"
        label="Spend Type"
        name="spend-type" />
      <FCInput
        v-model="notes"
        type="text"
        title="Notes"
        name="Notes" />
      <ButtonAction
        primary
        :disabled="loading"
        @click.native="sendSpendData">
        Submit
      </ButtonAction>
    </form>
    <p class="custom-spend__total">
      total: ${{ total }}
    </p>
    <div v-show="success">
      <button
        class="custom-spend__success"
        type="button"
        @click="reset">
        Success! (Press to reset)
      </button>
    </div>
    <p v-for="(event,index) in events" :key="index">
      <span> {{ JSON.stringify(event,null,2) }}</span>
    </p>
  </div>
</template>

<script>
  import { FCInput, FCSelect } from '@fc/angie-ui';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import customSpendTypes from '@/shared/datasets/custom_spend_types.json';

  export default {
    components: {
      FCInput,
      FCSelect,
      ButtonAction,
    },
    data() {
      return {
        type: '',
        spend: '',
        days: '',
        startDate: new Date(),
        spendTypeSelected: '',
        notes: '',
        loading: false,
        success: false,
      };
    },
    computed: {
      total() {
        return this.spend * this.days;
      },
      events() {
        const events = [];
        if (this.spend === 0 || !this.days || !this.spendTypeSelected) {
          return events;
        }

        const timeToAdd = 13.5 * 60 * 60 * 1000;
        const startDateInMs = new Date(this.startDate.toISOString().substring(0, 10)).getTime();

        for (let day = 0; day < this.days; day++) {
          const finalTime = new Date(startDateInMs + timeToAdd);
          const dateToRecord = new Date(finalTime.setDate(finalTime.getDate() + day)).toISOString();

          events.push({
            type: this.spendTypeSelected,
            note: this.notes,
            cost: parseFloat(this.spend).toFixed(2),
            spend_date: dateToRecord,
          });
        }
        return events;
      },
      getSpendTypeOptions() {
        return customSpendTypes.map(spendType => ({ key: spendType, name: spendType }));
      },
    },
    methods: {
      sendSpendData() {
        this.loading = true;

        Promise.all(this.events.map(event => Promise.resolve(this.$analytics.marketingSpendUpdated(event))))
          .then(() => {
            this.success = true;
          });
      },
      reset() {
        this.notes = '';
        this.spend = '';
        this.days = '';
        this.spendTypeSelected = '';
        this.success = false;
        this.loading = false;
      },
    },
  };
</script>

<style lang="scss">
  @import '@fc/angie-ui/dist/styles/exports';

  .custom-spend {

    &__total {
      font-size: get-font-size-level(10)
    }

    &__success {
      background-color: $color-system-green;
      border: none;
      color: $color-white;
      height: 3rem;
      border-radius: $button-radius;
      font-size: 2rem;
    }
  }
</style>
